import { LoadableComponent } from '@loadable/component';

export function preloadComponent<ComponentProps>(
  component: LoadableComponent<ComponentProps>,
): void {
  if (!component.preload) {
    return;
  }

  component.preload();
}
