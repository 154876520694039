import { FC, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import {
  useDisclosure,
  useBreakpointValue,
  HStack,
  VStack,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerFooter,
  DrawerCloseButton,
  Text,
} from '@chakra-ui/react';
import { FiMenu } from 'react-icons/fi';
import { container } from 'tsyringe';

import { AnalyticsService, EventAction, EventCategory } from '../../services';
import { ICompany } from '../../entities';

const links = [
  {
    endpoint: '/learn',
    label: 'Learn',
  },
  {
    endpoint: '/projects',
    label: 'Projects',
  },
  {
    endpoint: '/company',
    label: 'Company',
  },
  {
    endpoint: '/contact',
    label: 'Contact',
  },
];

const NavLinks: FC = () => {
  const analyticsService = container.resolve(AnalyticsService);

  return (
    <HStack spacing="32px" as="nav">
      {links.map((link) => (
        <NavLink
          activeStyle={{ fontWeight: 'bold' }}
          key={link.endpoint}
          to={link.endpoint}
          onClick={() =>
            analyticsService.event(
              EventAction.NAVIGATE,
              EventCategory.INTERNAL_URL,
            )
          }
        >
          {link.label}
        </NavLink>
      ))}
    </HStack>
  );
};

interface INavDrawerProps {
  company: ICompany;
}

const NavDrawer: FC<INavDrawerProps> = () => {
  const analyticsService = container.resolve(AnalyticsService);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <HStack>
        <IconButton
          aria-label="open navigation"
          colorScheme="transparent"
          size="lg"
          variant="ghost"
          ref={buttonRef}
          onClick={() => {
            analyticsService.event(EventAction.OPEN, EventCategory.SIDE_MENU);

            onOpen();
          }}
          icon={<FiMenu />}
        >
          Open
        </IconButton>
      </HStack>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={buttonRef}
        size="xs"
      >
        <DrawerOverlay />
        <DrawerContent bgColor="gray.900">
          <DrawerCloseButton
            onClick={() =>
              analyticsService.event(EventAction.CLOSE, EventCategory.SIDE_MENU)
            }
            color="whitesmoke"
          />
          <DrawerBody
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <VStack>
              {links.map((link) => (
                <NavLink
                  style={{
                    color: 'whitesmoke',
                    margin: '8px 0',
                    padding: '16px 0',
                    fontSize: 24,
                  }}
                  activeStyle={{
                    fontWeight: 'bold',
                    textDecoration: 'underline',
                  }}
                  key={link.endpoint}
                  to={link.endpoint}
                  onClick={() =>
                    analyticsService.event(
                      EventAction.NAVIGATE,
                      EventCategory.INTERNAL_URL,
                    )
                  }
                >
                  {link.label}
                </NavLink>
              ))}
            </VStack>
          </DrawerBody>
          <DrawerFooter>
            <blockquote>
              <Text fontStyle="italic" color="whitesmoke">
                "Kindness is the language which the deaf can hear and the blind
                can see"
                <br />- Mark Twain
              </Text>
            </blockquote>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

interface INavigationProps {
  company: ICompany;
}

export const Navigation: FC<INavigationProps> = ({ company }) => {
  const ResponsiveNav = useBreakpointValue({
    base: () => <NavDrawer company={company} />,
    md: NavLinks,
  });
  if (!ResponsiveNav) {
    return null;
  }

  return <ResponsiveNav />;
};
