export function isJSONParsable(input: unknown): input is string {
  if (typeof input !== 'string') {
    return false;
  }

  try {
    JSON.parse(input);

    return true;
  } catch (error: unknown) {
    return false;
  }
}
