import {
  initialize,
  modalview,
  pageview,
  event,
  timing,
  exception,
} from 'react-ga';
import { singleton } from 'tsyringe';

import { ConfigurationService, Environment } from './configuration.service';
import { LoggingService } from './logging.service';
import { isProduction } from '../utils';

export enum EventCategory {
  EXTERNAL_URL = 'external_url',
  INTERNAL_URL = 'internal_url',
  SIDE_MENU = 'side_menu',
  TOAST = 'toast',
  VIDEO_PLAYER = 'video_player',
  WEB_VITALS = 'web_vitals',
}

export enum EventAction {
  CLICK = 'click',
  OPEN = 'open',
  CLOSE = 'close',
  NAVIGATE = 'navigate',
  CLS = 'CLS',
  FCP = 'FCP',
  FID = 'FID',
  LCP = 'LCP',
  TTFB = 'TTFB',
}

export enum EventLabel {
  HOME_PAGE = 'home_page',
  CONTACT_PAGE = 'contact_page',
  LEARN_PAGE = 'learn_page',
  BSL_TRANSLATIONS_NOTIFICATION = 'bsl_translations_notification',
  FACEBOOK_LINK = 'facebook_link',
  TWITTER_LINK = 'twitter_link',
  INSTAGRAM_LINK = 'instagram_link',
  MOBILE = 'mobile',
  EMAIL = 'email',
  JAKE_PORTFOLIO_LINK = 'jake_portfolio_link',
  BOOK_AN_INTERPRETER_CAMPAIGN = 'book_an_interpreter_campaign',
}

@singleton()
export class AnalyticsService {
  private isInitialised = false;

  constructor(
    private readonly configService: ConfigurationService,
    private readonly loggingService: LoggingService,
  ) {
    const googleAnalyticsTrackingCode =
      this.configService.getGoogleAnalyticsTrackingCode();
    if (!googleAnalyticsTrackingCode) {
      this.loggingService.warn(
        'Analytics tracking code not found. This session will not be tacked.',
      );
      return;
    }

    const env = this.configService.getEnv();

    initialize(googleAnalyticsTrackingCode, {
      debug: env !== Environment.PRODUCTION,
      testMode: env === Environment.TEST,
    });

    this.isInitialised = true;

    this.loggingService.debug('Analytics session started.');
  }

  public pageView(path: string): void {
    this.sendMetric(pageview, path);
  }

  public modalView(name: string): void {
    this.sendMetric(modalview, name);
  }

  public event(
    action: EventAction,
    category: EventCategory,
    label?: EventLabel,
    nonInteraction?: boolean,
    value?: number,
  ): void {
    this.sendMetric(event, { action, category, label, nonInteraction, value });
  }

  public telemetry(
    category: EventCategory,
    variable: string,
    timing_ms: number,
    label?: EventLabel,
  ): void {
    this.sendMetric(timing, { category, variable, value: timing_ms, label });
  }

  public exception(message: string, fatal: boolean = false): void {
    this.sendMetric(exception, { message, fatal });
  }

  public generateExternalLabel(type: string): string {
    return `tracking_event-visit_outbound_link_${type}`;
  }

  private sendMetric(cb: (...args: any[]) => void, ...args: any[]): void {
    if (!isProduction()) {
      this.loggingService.debug(
        `Analytics metric for "${cb.name}" was NOT sent: `,
        ...args,
      );
      return;
    }

    if (!this.isInitialised) {
      this.loggingService.warn(
        'Analytics metric was not sent, the service is not initialised.',
      );
      return;
    }

    cb(...args);
  }
}
