import 'video.js/dist/video-js.css';

import { FC, useRef, useEffect } from 'react';
import initVideoJs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';
import {
  Container,
  Button,
  useBoolean,
  useTheme,
  useBreakpointValue,
  Box,
  Text,
  Portal,
} from '@chakra-ui/react';
import { FaSignLanguage } from 'react-icons/fa';
import { FiX } from 'react-icons/fi';
import { container } from 'tsyringe';

import { AnalyticsService, EventAction, EventCategory } from '../../services';
import { ErrorBoundary } from '../../utils';

export interface IVideoPlayerInstance extends VideoJsPlayer {}

interface IVideoPlayerProps {
  options: VideoJsPlayerOptions;
  onReady?: (player: IVideoPlayerInstance) => void;
  buttonLabel: string;
}

export const VideoPlayer: FC<IVideoPlayerProps> = ({
  options,
  onReady,
  buttonLabel,
}) => {
  const analyticsService = container.resolve(AnalyticsService);

  const [playerIsOpen, setPlayer] = useBoolean();
  const theme = useTheme();
  const videoElementRef = useRef<HTMLVideoElement>(null);
  const playerRef = useRef<IVideoPlayerInstance | null>(null);
  const position = useBreakpointValue({
    base: 0,
    md: 16,
  });
  const buttonBorderRadius = useBreakpointValue({
    base: 0,
    md: theme.radii.md,
  });

  useEffect(() => {
    if (!playerIsOpen) {
      return;
    }

    if (!playerRef.current) {
      const videoElement = videoElementRef.current;
      if (!videoElement) {
        return;
      }

      const player = (playerRef.current = initVideoJs(
        videoElement,
        {
          controls: true,
          autoplay: false,
          preload: 'auto',
          fluid: true,
          responsive: true,
          fill: true,
          controlBar: {
            volumePanel: false,
          },
          ...options,
        },
        () => {
          onReady && onReady(player);
        },
      ));
    }
  }, [options, videoElementRef, onReady, playerIsOpen]);

  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef, playerIsOpen]);

  return (
    <ErrorBoundary>
      <Portal>
        <Container
          pos="fixed"
          bottom={position}
          right={position}
          padding={0}
          zIndex={1}
          maxW="container.sm"
        >
          {playerIsOpen && (
            <Box pos="relative" bottom={16}>
              <Box
                pos="absolute"
                right={0}
                top={-10}
                paddingTop={2}
                paddingBottom={4}
                paddingLeft={4}
                paddingRight={4}
                borderWidth="1px"
                borderTopLeftRadius={theme.radii.xl}
                borderTopRightRadius={theme.radii.xl}
                backgroundColor="gray.200"
                display="flex"
                alignItems="center"
                userSelect="none"
                cursor="pointer"
                color="gray.600"
                onClick={() => {
                  analyticsService.event(
                    EventAction.CLOSE,
                    EventCategory.VIDEO_PLAYER,
                  );

                  setPlayer.off();
                }}
              >
                <Text fontSize="sm">Close Player</Text>{' '}
                <FiX style={{ marginLeft: 4 }} />
              </Box>
              <Box
                sx={{
                  '.video-js .vjs-control-bar': {
                    borderBottomLeftRadius: theme.radii.xl,
                    borderBottomRightRadius: theme.radii.xl,
                  },
                  '.video-js': {
                    borderRadius: theme.radii.xl,
                    borderTopRightRadius: 0,
                    borderWidth: '1px',
                    borderColor: theme.colors.gray[200],
                    background: 'white',
                  },
                }}
              >
                <div data-vjs-player>
                  <video
                    muted
                    autoPlay
                    ref={videoElementRef}
                    className="video-js vjs-big-play-centered"
                    style={{
                      borderRadius: theme.radii.xl,
                      borderTopRightRadius: 0,
                    }}
                  />
                </div>
              </Box>
            </Box>
          )}
          <Button
            pos="absolute"
            width={{ base: '100%', sm: 'auto' }}
            bottom={0}
            right={0}
            variant="solid"
            aria-label="open interpretation demonstration for the content on this page"
            rightIcon={<FaSignLanguage />}
            onClick={() => {
              analyticsService.event(
                EventAction.OPEN,
                EventCategory.VIDEO_PLAYER,
              );

              setPlayer.on();
            }}
            size="lg"
            disabled={playerIsOpen}
            borderBottomLeftRadius={buttonBorderRadius}
            borderBottomRightRadius={buttonBorderRadius}
          >
            {buttonLabel}
          </Button>
        </Container>
      </Portal>
    </ErrorBoundary>
  );
};
