import { singleton } from 'tsyringe';

@singleton()
export class LoggingService {
  private logger: Console;

  constructor() {
    this.logger = console;
  }

  public info(...args: unknown[]): void {
    this.logger.info(`[${this.timestamp}]: INFO - `, ...args);
  }

  public warn(...args: unknown[]): void {
    this.logger.warn(`[${this.timestamp}]: WARN - `, ...args);
  }

  public error(...args: unknown[]): void {
    this.logger.error(`[${this.timestamp}]: ERROR - `, ...args);
  }

  public debug(...args: unknown[]): void {
    this.logger.debug(`[${this.timestamp}]: DEBUG - `, ...args);
  }

  private get timestamp(): string {
    const now = new Date();

    return now.toLocaleTimeString();
  }
}
