import { FC, ReactNode } from 'react';

import { Header, Footer } from '../components';
import companyData from '../data/company.json';
import employeesData from '../data/employees.json';
import { Company, Employee, ICompany } from '../entities';

interface ILayoutProviderProps {
  children: (company: ICompany) => ReactNode;
}

const employees = employeesData.employees.map(
  (e) => new Employee(e.id, e.name, e.role),
);

const company = new Company(
  companyData.id,
  companyData.name,
  companyData.externalLinks,
  companyData.contact,
  companyData.address,
  employees,
);

export const LayoutProvider: FC<ILayoutProviderProps> = ({ children }) => {
  return (
    <>
      <Header company={company} />
      <main>{children(company)}</main>
      <Footer company={company} />
    </>
  );
};
