import { FC, PropsWithChildren } from 'react';

interface ISVGProps extends PropsWithChildren {
  viewBox: string;
}

export const SVG: FC<ISVGProps> = ({ children, viewBox }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      width="100%"
      viewBox={viewBox}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      {children}
    </svg>
  );
};
