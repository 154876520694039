import { container } from 'tsyringe';

import { ConfigurationService, Environment } from '../services';

export function isProduction(): boolean {
  const configService = container.resolve(ConfigurationService);
  const env = configService.getEnv();

  return env === Environment.PRODUCTION;
}
