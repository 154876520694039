import { singleton } from 'tsyringe';

import { isJSONParsable } from '../utils/is-json-parsable';

type LocalStorageVersion = number;

interface ILocalStorage {
  version: number;
  hasClosedLatestNewsToast: boolean;
}

@singleton()
export class LocalStorageService {
  public getBoolean(key: keyof ILocalStorage, fallbackValue = false): boolean {
    const value = this.get(key);
    if (value === undefined) {
      return fallbackValue;
    }

    return Boolean(value);
  }

  public getObject<T>(key: keyof ILocalStorage, fallbackValue: T): T {
    const value = this.get(key);
    if (isJSONParsable(value)) {
      return JSON.parse(value);
    }

    return fallbackValue;
  }

  public getNumber(key: keyof ILocalStorage, fallbackValue: number): number {
    const value = this.get(key);
    if (!isNaN(Number(value))) {
      return Number(value);
    }

    return fallbackValue;
  }

  public getDate(
    key: keyof ILocalStorage,
    fallbackValue?: Date,
  ): Date | undefined {
    const value = this.get(key);
    if (!value) {
      return fallbackValue;
    }

    if (Date.parse(value)) {
      return new Date(value);
    }

    return fallbackValue;
  }

  public getString(
    key: keyof ILocalStorage,
    fallbackValue?: string,
  ): string | undefined {
    const value = this.get(key);
    if (!value) {
      return fallbackValue;
    }

    return value;
  }

  public getLocalStorageVersion(): LocalStorageVersion {
    const value = this.getNumber('version', 1);

    return value;
  }

  public setLocalStorageVersion(version: number): void {
    this.upsert('version', version);
  }

  public upsert<T>(key: keyof ILocalStorage, value: T): void {
    if (Array.isArray(value)) {
      localStorage.setItem(key, value.join());
    }

    if (!value) {
      localStorage.removeItem(key);

      return;
    }

    if (typeof value === 'string') {
      localStorage.setItem(key, value);

      return;
    }

    if (typeof value === 'number') {
      localStorage.setItem(key, value.toString());

      return;
    }

    if (value instanceof Date) {
      localStorage.setItem(key, value.toISOString());

      return;
    }

    return localStorage.setItem(key, JSON.stringify(value));
  }

  private get(key: keyof ILocalStorage): string | undefined {
    if (typeof key !== 'string') {
      return undefined;
    }

    const value = localStorage.getItem(key);

    return value || undefined;
  }
}
