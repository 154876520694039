import 'reflect-metadata';
import { StrictMode, FC } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { container } from 'tsyringe';

import { Routes } from './routes/routes.component';
import { theme, monitorWebVitals, isProduction } from './utils';
import { LocalStorageService, ConfigurationService } from './services';

const localStorageService = container.resolve(LocalStorageService);
const configService = container.resolve(ConfigurationService);

const currentAppVersion = configService.getAppVersion();
if (localStorageService.getLocalStorageVersion() < currentAppVersion) {
  localStorageService.setLocalStorageVersion(currentAppVersion);
}

if (isProduction()) {
  monitorWebVitals();
}

const App: FC = () => {
  return (
    <StrictMode>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </ChakraProvider>
    </StrictMode>
  );
};

const domNode = document.getElementById('root')!;
const root = createRoot(domNode);

root.render(<App />);
