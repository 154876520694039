import { FC } from 'react';
import { Box, Container, Text, Icon } from '@chakra-ui/react';
import { FiFacebook, FiInstagram, FiTwitter } from 'react-icons/fi';
import loadable from '@loadable/component';

import { Link } from '../link';
import { ICompany } from '../../entities';
import { EventLabel } from '../../services';

const GoogleMaps = loadable(() => import('../google-maps'));
const TwitterFeed = loadable(() => import('../twitter-feed'));

interface IFooterProps {
  company: ICompany;
}

export const Footer: FC<IFooterProps> = ({ company }) => {
  return (
    <Box
      maxW="full"
      bgColor="gray.800"
      pt={8}
      pb={16}
      as="footer"
      role="contentinfo"
    >
      <Container maxW="container.lg">
        <Box
          display="flex"
          justifyContent="space-between"
          flexDir="row"
          alignItems="center"
          flexWrap="wrap"
          mb={4}
        >
          <Box mb={4} ml="auto" mr="auto">
            <Text fontWeight="bold" color="white" fontSize="xl" mb={4}>
              Keep up to date <span aria-label="loudspeaker">📢</span>
            </Text>
            <TwitterFeed />
          </Box>
          <Box mb={4} ml="auto" mr="auto">
            <Text fontWeight="bold" color="white" fontSize="xl" mb={4}>
              Find us <span aria-label="office building">🏢</span>
            </Text>
            <GoogleMaps />
          </Box>
        </Box>
        <Text
          mb={4}
          textAlign="center"
          fontWeight="bold"
          color="gray.600"
          fontSize="md"
        >
          © 2016 - {new Date().getFullYear()} {company.name}. All Rights
          Reserved.
        </Text>
        <Box
          ml="auto"
          mr="auto"
          maxW={160}
          mb={4}
          display="flex"
          justifyContent="space-between"
        >
          <Link
            to={company.externalLinks.facebook}
            isExternal
            aria-label="Facebook External Link"
            color="white"
            eventLabel={EventLabel.FACEBOOK_LINK}
          >
            <Icon as={FiFacebook} w={6} h={6} />
          </Link>
          <Link
            to={company.externalLinks.twitter}
            isExternal
            aria-label="Twitter External Link"
            color="white"
            eventLabel={EventLabel.TWITTER_LINK}
          >
            <Icon as={FiTwitter} w={6} h={6} />
          </Link>
          <Link
            to={company.externalLinks.instagram}
            isExternal
            aria-label="Instagram External Link"
            color="white"
            eventLabel={EventLabel.INSTAGRAM_LINK}
          >
            <Icon as={FiInstagram} w={6} h={6} />
          </Link>
        </Box>
        <Link
          isExternal
          to="https://jakeswork.github.io/portfolio/"
          color="gray.700"
          eventLabel={EventLabel.JAKE_PORTFOLIO_LINK}
        >
          Website by Jake
        </Link>
      </Container>
    </Box>
  );
};
