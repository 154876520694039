type EmployeeIdentifier = number;

export interface IEmployee {
  id: EmployeeIdentifier;
  name: string;
  role: string;
}

export class Employee implements IEmployee {
  constructor(
    public readonly id: EmployeeIdentifier,
    public readonly name: string,
    public readonly role: string,
  ) {}
}
