import { IEmployee } from './employee.entity';

type CompanyIdentifier = number;

export interface ICompany {
  id: CompanyIdentifier;
  name: string;
  externalLinks: Record<'facebook' | 'twitter' | 'instagram', string>;
  contact: Record<
    'generalEnquiriesEmailAddress' | 'trainingEmailAddress' | 'mobile',
    string
  >;
  address: string;
  employees: IEmployee[];
}

export class Company implements ICompany {
  constructor(
    public readonly id: CompanyIdentifier,
    public readonly name: string,
    public readonly externalLinks: Record<
      'facebook' | 'twitter' | 'instagram',
      string
    >,
    public readonly contact: Record<
      'generalEnquiriesEmailAddress' | 'trainingEmailAddress' | 'mobile',
      string
    >,
    public readonly address: string,
    public readonly employees: IEmployee[],
  ) {}
}
