import { FC } from 'react';
import {
  Heading,
  Box,
  Flex,
  Container,
  useBreakpointValue,
} from '@chakra-ui/react';

interface IJumbotronProps {
  heading: JSX.Element;
  subheading: JSX.Element;
  Illustration?: JSX.Element;
}

export const Jumbotron: FC<IJumbotronProps> = ({
  heading,
  subheading,
  Illustration,
}) => {
  const shouldRenderIllustration = useBreakpointValue({
    base: false,
    md: true,
  });
  const textContainerMaxWidth = useBreakpointValue({
    base: 'none',
    md: '50%',
  });

  return (
    <Box as="section" bgColor="brand.100" pt={24} pb={24}>
      <Container maxW="container.lg">
        <Flex>
          <Box mr={16} maxW={textContainerMaxWidth}>
            <Heading as="h1" size="2xl" mb={8}>
              {heading}
            </Heading>
            <Heading size="lg" as="h2" fontWeight="normal">
              {subheading}
            </Heading>
          </Box>
          {shouldRenderIllustration && Illustration && Illustration}
        </Flex>
      </Container>
    </Box>
  );
};
