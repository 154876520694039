import { useLayoutEffect, useEffect, FC } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { container } from 'tsyringe';
import loadable from '@loadable/component';

import { LayoutProvider, preloadComponent } from '../utils';
import { AnalyticsService } from '../services';

const Index = loadable(() => import('./index/index.route'));
const Learn = loadable(() => import('./learn/learn.route'));
const Projects = loadable(() => import('./projects/projects.route'));
const Contact = loadable(() => import('./contact/contact.route'));
const Company = loadable(() => import('./company/company.route'));
const FourZeroFour = loadable(() => import('./404/404.route'));

export const Routes: FC = () => {
  const { pathname } = useLocation();
  const analyticsService = container.resolve(AnalyticsService);

  useEffect(() => {
    preloadComponent(Index);
    preloadComponent(Learn);
    preloadComponent(Projects);
    preloadComponent(Contact);
    preloadComponent(Company);
    preloadComponent(FourZeroFour);
  }, []);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    analyticsService.pageView(pathname);
  }, [pathname, analyticsService]);

  return (
    <LayoutProvider>
      {(company) => (
        <Switch>
          <Route path="/" exact component={() => <Index company={company} />} />
          <Route
            path="/learn"
            exact
            component={() => <Learn company={company} />}
          />
          <Route path="/projects" exact component={Projects} />
          <Route
            path="/contact"
            exact
            component={() => <Contact company={company} />}
          />
          <Route
            path="/company"
            exact
            component={() => <Company company={company} />}
          />
          <Route path="/" component={FourZeroFour} />
        </Switch>
      )}
    </LayoutProvider>
  );
};
