import { FC } from 'react';
import { Link as RRDomLink } from 'react-router-dom';
import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from '@chakra-ui/react';
import { container } from 'tsyringe';

import {
  AnalyticsService,
  EventAction,
  EventCategory,
  EventLabel,
} from '../../services';

export interface ILinkProps extends ChakraLinkProps {
  to: string;
  isExternal?: boolean;
  eventLabel?: EventLabel;
}

export const Link: FC<ILinkProps> = ({
  isExternal = false,
  to,
  eventLabel,
  ...props
}) => {
  const sharedProps = {
    fontWeight: 'bold',
    _hover: {
      textDecoration: 'none',
    },
  };

  if (isExternal) {
    const analyticsService = container.resolve(AnalyticsService);

    return (
      <ChakraLink
        {...sharedProps}
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        display="block"
        mb={4}
        textAlign="center"
        fontSize="sm"
        onClick={(event) => {
          if (props.onClick && typeof props.onClick === 'function') {
            props.onClick(event);
          }

          analyticsService.event(
            EventAction.NAVIGATE,
            EventCategory.EXTERNAL_URL,
            eventLabel,
          );
        }}
        {...props}
      />
    );
  }

  return <ChakraLink {...sharedProps} as={RRDomLink} to={to} {...props} />;
};
