import { FC } from 'react';
import { Link as RRDomLink } from 'react-router-dom';
import {
  Flex,
  Spacer,
  Image,
  LinkOverlay,
  LinkBox,
  Container,
  Box,
  Text,
  useTheme,
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import { container } from 'tsyringe';
import { FiExternalLink } from 'react-icons/fi';

import { Navigation } from '../navigation';
import { ICompany } from '../../entities';
import {
  AnalyticsService,
  EventAction,
  EventCategory,
  EventLabel,
} from '../../services';
import { Link } from '../link';

import TheSignLifeLogoWebp from '../../assets/logos/thesignlife-logo.webp';
import TheSignLifeLogoPng from '../../assets/logos/thesignlife-logo.png';

const BookAnInterpreterCampaignBanner: FC = () => {
  const theme = useTheme();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const analyticsService = container.resolve(AnalyticsService);

  return (
    <>
      <Box
        backgroundColor="gray.900"
        p={2}
        pos="fixed"
        zIndex={2}
        left={0}
        right={0}
        onClick={onOpen}
        display="flex"
        justifyContent="center"
        alignItems="center"
        color="white"
      >
        <Text fontSize="sm" fontWeight="bold">
          Join the{' '}
          <span
            style={{
              textDecoration: 'underline',
            }}
          >
            #bookaninterpreter
          </span>{' '}
          campaign&nbsp;
          <span aria-label="ribbon">🎗️</span>
        </Text>
        <Button
          onClick={onOpen}
          marginLeft={4}
          paddingTop={2}
          paddingBottom={2}
          paddingLeft={4}
          paddingRight={4}
          borderRadius={theme.radii.md}
          display="flex"
          backgroundColor="rgb(255, 255, 255, 0.1)"
          variant="unstyled"
          size="sm"
        >
          Learn More
        </Button>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="2xl">#bookaninterpreter</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize="lg">
              We must put a stop to professionals in medical settings having to
              use CODA's (Children of Deaf Adults) or other family members as
              interpreters at medical appointments. It should be a requirement
              that qualified BSL (British Sign Language) interpreters are booked
              for such occasions.
            </Text>
            <br />
            <Text fontSize="lg">
              Please help us raise as many signatures as we can to prevent this
              from continuing. You can search for the hashtag{' '}
              <strong>#bookaninterpreter</strong> to follow the campaign or on
              TheSignLife's social media pages to discover real life,
              heartbreaking stories from the Deaf community regarding this
              topic.
            </Text>
            <br />
            <Text fontSize="lg">
              You can read more about Pearl's story in the following articles:
            </Text>
            <br />
            <Link
              isExternal
              to="https://www.birminghammail.co.uk/news/midlands-news/heartbreak-schoolgirl-sign-deaf-mum-20414639"
              background="gray.100"
              borderRadius={theme.radii.md}
              textDecor="underline"
              fontSize="md"
              p={2}
              textAlign="left"
              _hover={{ textDecor: 'underline' }}
            >
              Heartbreak as schoolgirl had to sign to her deaf mum that her dad
              had died
              <FiExternalLink style={{ display: 'inline', marginLeft: 4 }} />
            </Link>
            <Link
              isExternal
              to="https://www.itv.com/news/central/2021-11-21/petition-stop-hospitals-using-children-as-interpreters-deaf-parents"
              background="gray.100"
              borderRadius={theme.radii.md}
              textDecor="underline"
              fontSize="md"
              p={2}
              textAlign="left"
              _hover={{ textDecor: 'underline' }}
            >
              Petition launched to stop hospitals using children to interpret
              sign language to deaf family members
              <FiExternalLink style={{ display: 'inline', marginLeft: 4 }} />
            </Link>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose} mr={4} variant="ghost" size="lg">
              Close
            </Button>
            <LinkBox
              onClick={() =>
                analyticsService.event(
                  EventAction.NAVIGATE,
                  EventCategory.EXTERNAL_URL,
                  EventLabel.BOOK_AN_INTERPRETER_CAMPAIGN,
                )
              }
            >
              <LinkOverlay
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.change.org/p/hospitals-using-bsl-interpreters-for-all-medical-settings?redirect=true"
              >
                <Button
                  rightIcon={<FiExternalLink />}
                  colorScheme="brand"
                  size="lg"
                >
                  Sign The Petition
                </Button>
              </LinkOverlay>
            </LinkBox>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

interface IHeaderProps {
  company: ICompany;
}

export const Header: FC<IHeaderProps> = ({ company }) => {
  const analyticsService = container.resolve(AnalyticsService);

  return (
    <Box bgColor="brand.100">
      <BookAnInterpreterCampaignBanner />
      <Container maxW="container.lg" paddingTop={8}>
        <Flex pt={6} pb={6} pos="sticky" top="0" as="header">
          <LinkBox
            onClick={() =>
              analyticsService.event(
                EventAction.NAVIGATE,
                EventCategory.INTERNAL_URL,
                EventLabel.HOME_PAGE,
              )
            }
          >
            <LinkOverlay as={RRDomLink} to="/">
              <picture>
                <source type="image/webp" srcSet={TheSignLifeLogoWebp} />
                <source type="image/png" srcSet={TheSignLifeLogoPng} />
                <Image
                  src={TheSignLifeLogoPng}
                  alt="TheSignLife Company Logo"
                  width="150px"
                />
              </picture>
            </LinkOverlay>
          </LinkBox>
          <Spacer />
          <Navigation company={company} />
        </Flex>
      </Container>
    </Box>
  );
};
