import { FC, PropsWithChildren } from 'react';
import {
  Container,
  Box,
  Text,
  Heading,
  Button,
  useTheme,
  Code,
} from '@chakra-ui/react';
import { container } from 'tsyringe';
import {
  ErrorBoundary as ReactErrorBoundary,
  FallbackProps,
} from 'react-error-boundary';

import { LoggingService, AnalyticsService } from '../services';

const ErrorFallback: FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  const theme = useTheme();

  return (
    <Container
      borderRadius={theme.radii.xl}
      borderWidth="1px"
      maxW="container.md"
      padding={8}
      margin={4}
      backgroundColor="white"
    >
      <Box role="alert">
        <Heading size="lg" mb={4}>
          Oops! &nbsp;
          <span aria-label="crying face">😭</span>
        </Heading>
        <Text fontSize="xl">
          Something went wrong and we're unable to show you this content.
        </Text>
        <Text fontSize="xl">
          If you come across any geeks, tell them about this error:
        </Text>
        <Box mt={4} mb={4}>
          <Code fontSize="lg">{error.message}</Code>
        </Box>
        <Text fontSize="xl" mb={4}>
          You can also try to reload this content by hitting the button below
          <span aria-label="pointing down finger">👇</span>
        </Text>
        <Button size="lg" variant="solid" onClick={resetErrorBoundary}>
          Reload
        </Button>
      </Box>
    </Container>
  );
};

export const ErrorBoundary: FC<PropsWithChildren> = ({ children }) => {
  const loggingService = container.resolve(LoggingService);
  const analyticsService = container.resolve(AnalyticsService);

  const logError = (error: Error, info: { componentStack: string }) => {
    loggingService.error(error, info);
    analyticsService.exception(`${error.message} in ${info.componentStack}`);
  };

  return (
    <ReactErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
      {children}
    </ReactErrorBoundary>
  );
};
