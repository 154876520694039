import { singleton } from 'tsyringe';

export enum Environment {
  PRODUCTION = 'PRODUCTION',
  DEVELOPMENT = 'DEVELOPMENT',
  TEST = 'TEST',
}

@singleton()
export class ConfigurationService {
  public getAppVersion(): number {
    const version = process.env.REACT_APP_VERSION || 1;

    return Number(version);
  }

  public getGoogleMapsApiKey(): string {
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    if (!apiKey) {
      const message = 'Missing Google Maps API Key';

      throw new Error(message);
    }

    return apiKey;
  }

  public getGoogleAnalyticsTrackingCode(): string | undefined {
    const googleAnalyticsTrackingCode =
      process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE;

    return googleAnalyticsTrackingCode;
  }

  public getEnv(): Environment {
    const env =
      (process.env.REACT_APP_ENV as Environment) || Environment.DEVELOPMENT;

    return env;
  }
}
