import { Box, Text, Button, Kbd } from '@chakra-ui/react';
import { container } from 'tsyringe';

import {
  AnalyticsService,
  EventAction,
  EventCategory,
  EventLabel,
} from '../../services';
import { ToastFactory } from './types';

export const bslTranslationsToastId = 'bsl-translations';

export const generateBslTranslationsToast: ToastFactory = (onClose) => {
  const analyticsService = container.resolve(AnalyticsService);

  return {
    id: bslTranslationsToastId,
    title: 'New! BSL Interpretations',
    description: (
      <Box>
        <Text>
          You can view interpretation videos across our site to get more
          information on the page's content.
        </Text>
        <Text>
          Look out for the <Kbd>Watch Interpretation</Kbd> button to try it out!
        </Text>
        <Button mt={4} onClick={onClose}>
          Okay
        </Button>
      </Box>
    ),
    status: 'info',
    onCloseComplete: () => {
      analyticsService.event(
        EventAction.CLOSE,
        EventCategory.TOAST,
        EventLabel.BSL_TRANSLATIONS_NOTIFICATION,
      );

      onClose();
    },
    isClosable: true,
    variant: 'subtle',
    position: 'bottom-left',
    duration: null,
  };
};
