import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
  colors: {
    brand: {
      100: '#f1e8da',
      200: '#dcc6a3',
      300: '#d5bb90',
      400: '#ceaf7e', // main colour
      500: '#c7a46b',
      600: '#c09859',
      700: '#b98d46',
      800: '#a67f3f',
      900: '#947138',
    },
  },
});
