import { ReportHandler } from 'web-vitals';
import { container } from 'tsyringe';

import {
  AnalyticsService,
  EventAction,
  EventCategory,
  EventLabel,
} from '../services';

export function monitorWebVitals(): void {
  const reportWebVitals = (onPerfEntry?: ReportHandler) => {
    if (onPerfEntry && onPerfEntry instanceof Function) {
      import('web-vitals').then(
        ({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
          getCLS(onPerfEntry);
          getFID(onPerfEntry);
          getFCP(onPerfEntry);
          getLCP(onPerfEntry);
          getTTFB(onPerfEntry);
        },
      );
    }
  };

  const analyticsService = container.resolve(AnalyticsService);

  reportWebVitals(({ id, name, value }) => {
    analyticsService.event(
      name as EventAction,
      EventCategory.WEB_VITALS,
      id as EventLabel,
      true,
      Math.round(name === EventAction.CLS ? value * 1000 : value),
    );
  });
}
